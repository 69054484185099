import { makeAutoObservable } from "mobx";
import { Auth } from "aws-amplify";
import { generateULID } from "frontend-shared/utils";

const refreshSession = async cognitoUser => {
  const session = await Auth.currentSession();
  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(session.getRefreshToken(), async (err, updatedSession) => {
      if (err) reject(err);
      resolve(updatedSession);
    });
  });
};

class AuthStore {
  constructor() {
    makeAutoObservable(this);
    this.checkForAuthenticatedUser();
  }

  authDetermined = false;
  authenticated = false;
  user = {};
  username;
  groups = [];
  forcePasswordReset = false;
  newUser = {};
  tempSignupData = {};

  setNewUser = user => (this.newUser = user);

  get sub() {
    return this.user?.attributes?.sub;
  }

  loading = false;

  async refreshUserSession() {
    const prevSessionUser = await Auth.currentAuthenticatedUser();
    await refreshSession(prevSessionUser);
    this.loading = true;
    try {
      await this.checkForAuthenticatedUser();
    } catch (err) {
    } finally {
      this.loading = false;
    }
  }

  async checkForAuthenticatedUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();
      const { "cognito:groups": groups } = session?.accessToken?.payload || {};
      if (user) {
        this.user = user;
        this.groups = groups;
        this.authenticated = true;
      } else {
        this.user = {};
        this.authenticated = false;
      }
    } catch (err) {
      // This also means user isn't authenticated
      this.authenticated = false;
    }

    this.authDetermined = true;

    // this.authenticated = true;
    // this.authDetermined = true;
  }

  async signIn(username, password) {
    this.loading = true;
    try {
      const authUser = await Auth.signIn(username || "-", password || "-");
      const { challengeName } = authUser || {};
      if (challengeName === "NEW_PASSWORD_REQUIRED") {
        this.setNewUser(authUser);
        this.forcePasswordReset = true;
      } else {
        const user = await Auth.currentAuthenticatedUser();
        this.user = user;
        this.authenticated = true;
      }

      this.loading = false;
      return { success: true };
    } catch (err) {
      console.warn(err);
      this.authenticated = false;
      this.loading = false;
      return { success: false, error: err.message };
    }
  }

  async signUp({ email, password, firstName, lastName }) {
    this.loading = true;

    let success, error;
    try {
      let username = generateULID();
      this.tempSignupData = { username, password };
      await Auth.signUp({
        username,
        password,
        attributes: {
          email,
          given_name: firstName,
          family_name: lastName
        }
      });
      success = true;
    } catch (err) {
      console.warn(err);
      success = false;
      error = err.message;
    }

    this.loading = false;
    return { success, error };
  }

  async verifyAccount(username, password, code) {
    this.loading = true;
    try {
      await Auth.confirmSignUp(username, code, {
        forceAliasCreation: true,
        clientMetadata: { priceId: "price_1LLZL2EGjWuZ23pUjIFhLhRo" }
      });
      await this.signIn(username, password);
      return { success: true };
    } catch (err) {
      this.loading = false;
      return { success: false, error: err.message };
    }
  }

  async sendResetPasswordCode(email) {
    this.loading = true;
    try {
      await Auth.forgotPassword(email);
      this.loading = false;
      return { success: true };
    } catch (err) {
      console.warn(err);
      this.loading = false;
      return { success: false, error: err.message };
    }
  }

  async resetPassword(email, code, newPassword) {
    this.loading = true;
    try {
      if (!this.username) {
        this.username = email.replace(/[^a-z0-9]/gi, "");
      }
      await Auth.forgotPasswordSubmit(this.username, code, newPassword);
      this.loading = false;
      return { success: true };
    } catch (e) {
      this.loading = false;
      return { success: false, error: e.message };
    }
  }

  async changeNewAccountPassword(newPassword) {
    if (this.newUser) {
      await Auth.completeNewPassword(this.newUser, newPassword);
      await this.signIn(this.newUser?.username, newPassword);
      this.setNewUser(null);
      this.forcePasswordReset = false;
      return true;
    }
  }

  async signOut() {
    try {
      await Auth.signOut();
      this.user = {};
      this.authenticated = false;
    } catch (err) {
      console.warn(err);
    }
  }
}

export default new AuthStore();
